/** @format */

import { Component, OnInit } from '@angular/core';
import { malCApp } from '@iq/ng-core';
import { MatIconRegistry } from '@angular/material/icon';

/**
 *  Main component of application.
 *
 * @export
 * @class       AppComponent
 * @extends     {malCApp}
 * @implements  {OnInit}
 */
@Component({
  selector: 'app-ipf',
  templateUrl: './app.component.html',
})
export class AppComponent extends malCApp implements OnInit {
  /**
   *  The title of the application.
   *
   * @memberof AppComponent
   */
  title = 'app';

  /**
   * Creates an instance of MainContainerComponent.
   *
   * @memberof  MainContainerComponent
   */
  constructor(private matIconRegistry: MatIconRegistry) {
    super();
    matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof MainContainerComponent
   */
  ngOnInit() {}
}
