<!-- @format -->

<ng-container *ngIf="newFormGroup">
  <h2
    mat-dialog-title
    i18n="add-edit-meeting-dialog dialog-title"
    *ngIf="formIsNew"
  >
    Nieuw overleg toevoegen
  </h2>
  <h2
    i18n="add-edit-meeting-dialog dialog-title"
    *ngIf="!formIsNew"
  >
    Wijzigen overleg
  </h2>

  <mat-dialog-content *ngIf="newFormGroup">
    <div
      class="agenda-template"
      *ngIf="agendaTemplatesList"
    >
      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Agenda template</mat-label>
          <mat-select
            formControlName="consultationTypeId"
            [disabled]="!formIsNew"
            (selectionChange)="onTemplateChange($event)"
          >
            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let template of this.agendaTemplatesList"
              [value]="template.id"
            >
              {{ template.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>

    <div class="agenda-name">
      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Titel</mat-label>
          <input
            type="text"
            matInput
            formControlName="title"
            required
          />
          <mat-error [hidden]="!formErrors.title">{{
            formErrors.title
          }}</mat-error>
        </mat-form-field>
      </form>
    </div>

    <mat-accordion
      displayMode="flat"
      multi
    >
      <mat-expansion-panel
        class="mat-elevation-z0"
        expanded
      >
        <mat-expansion-panel-header>
          <mat-panel-title
            i18n="add-edit-agenda-meeting-dialog expansion-panel-title"
          >
            Datums
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="dates-container">
          <div class="flex-rows">
            <form [formGroup]="newFormGroup">
              <mat-form-field appearance="fill">
                <mat-label>Begindatum</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="beginDate"
                  [matDatepicker]="pickerBegin"
                  required
                />
                <mat-datepicker #pickerBegin></mat-datepicker>
                <mat-error [hidden]="!formErrors.beginDate">{{
                  formErrors.beginDate
                }}</mat-error>
                <mat-icon (click)="pickerBegin.open()">calendar_month</mat-icon>
              </mat-form-field>
            </form>

            <form [formGroup]="newFormGroup">
              <mat-form-field
                appearance="fill"
                class="duration"
              >
                <mat-label>Duur</mat-label>
                <input
                  type="time"
                  matInput
                  formControlName="duration"
                  readonly
                />
                <mat-error [hidden]="!formErrors.duration">{{
                  formErrors.duration
                }}</mat-error>
              </mat-form-field>
            </form>
          </div>

          <div class="flex-rows">
            <ng-template #cancelBtn>
              <button
                mat-flat-button
                color="warn"
                class="small"
                i18n="cancel button @@buttonCancel"
              >
                Annuleren
              </button>
            </ng-template>

            <ng-template #confirmBtn>
              <button
                mat-flat-button
                color="accent"
                class="small"
                i18n="confirm button @@buttonConfirm"
              >
                Ok
              </button>
            </ng-template>

            <form [formGroup]="newFormGroup">
              <mat-form-field appearance="fill">
                <mat-label>Begintijd</mat-label>
                <input
                  type="time"
                  matInput
                  [ngxTimepicker]="picker"
                  [format]="24"
                  required
                  formControlName="beginHour"
                  [disableClick]="true"
                />
                <ngx-material-timepicker-toggle
                  [for]="picker"
                ></ngx-material-timepicker-toggle>
                <ngx-material-timepicker
                  #picker
                  [cancelBtnTmpl]="cancelBtn"
                  [confirmBtnTmpl]="confirmBtn"
                ></ngx-material-timepicker>
                <mat-error [hidden]="!formErrors.beginHour">{{
                  formErrors.beginHour
                }}</mat-error>
              </mat-form-field>
            </form>

            <form [formGroup]="newFormGroup">
              <mat-form-field appearance="fill">
                <mat-label>Eindtijd</mat-label>
                <input
                  type="time"
                  matInput
                  [ngxTimepicker]="picker2"
                  [format]="24"
                  required
                  formControlName="endHour"
                  [disableClick]="true"
                />
                <ngx-material-timepicker-toggle
                  [for]="picker2"
                ></ngx-material-timepicker-toggle>
                <ngx-material-timepicker
                  #picker2
                  [cancelBtnTmpl]="cancelBtn"
                  [confirmBtnTmpl]="confirmBtn"
                ></ngx-material-timepicker>
                <mat-error [hidden]="!formErrors.endHour">{{
                  formErrors.endHour
                }}</mat-error>
              </mat-form-field>
            </form>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title
            i18n="add-edit-agenda-meeting-dialog expansion-panel-title"
          >
            Deelnemers
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-meeting-members-list
          [meetingMembers]="initialFormValues?.meetingMembers[0]"
          [updateRights]="canUpdate"
          (membersChanged)="onMeetingMembersChange($event)"
          [meetingId]="savedMeeting ? savedMeeting.id : ''"
        >
        </app-meeting-members-list>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title
            i18n="add-edit-agenda-meeting-dialog expansion-panel-title"
          >
            Agendapunten
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div class="subjects">
            <div class="children-expansion-container">
              <mat-accordion
                displayMode="flat"
                multi
                #subjectsListDrop="cdkDropList"
                cdkDropList
                [cdkDropListData]="subjectsList"
                [cdkDropListConnectedTo]="[yearlySubjects]"
                (cdkDropListDropped)="drop($event)"
              >
                <mat-expansion-panel
                  class="mat-elevation-z0 insertable-item"
                  #expPanel
                  *ngIf="!subjectsList.length"
                  cdkDrag
                  cdkDragDisabled
                  disabled
                >
                  <mat-expansion-panel-header> </mat-expansion-panel-header>
                </mat-expansion-panel>

                <ng-container *ngIf="subjectsList.length">
                  <mat-expansion-panel
                    class="mat-elevation-z0 draggable-items insertable-item"
                    #expPanel
                    *ngFor="let subject of subjectsList; let ind = index"
                    [ngClass]="{
                      'is-new': subject.temporaryFields?.isNew,
                      'removed': subject.temporaryFields?.removed,
                      'has-error': subject.temporaryFields?.invalid
                    }"
                    [disabled]="subject.temporaryFields?.removed || !canUpdate"
                    [expanded]="subject.temporaryFields?.expanded && canUpdate"
                    cdkDrag
                    [cdkDragDisabled]="
                      subject.temporaryFields?.isNew ||
                      expPanel.expanded ||
                      !canUpdate
                    "
                    [cdkDragData]="subject"
                    (opened)="subject.expanded = true"
                    (closed)="subject.expanded = false"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title
                        [ngClass]="{
                          'has-yearly': subject.yearlyPlannedSubjectId
                        }"
                      >
                        <mat-icon *ngIf="subject.temporaryFields?.invalid"
                          >error</mat-icon
                        >

                        <span>{{
                          subject.name
                            ? subject.name
                            : 'Nieuwe agendapunt ' + (ind + 1)
                        }}</span>

                        <div class="mat-expansion-panel-header-label">
                          <span *ngIf="subject.yearlyPlannedSubjectId"
                            >Jaarlijks</span
                          >
                        </div>

                        <div class="cud-icons">
                          <button
                            mat-icon-button
                            color="accent"
                            *ngIf="
                              (canUpdate || formIsNew) &&
                              subject.temporaryFields?.removed
                            "
                            (click)="
                              onChildItemRestore(subject, 0, $event, ind)
                            "
                          >
                            <mat-icon>restore_from_trash</mat-icon>
                          </button>

                          <button
                            mat-icon-button
                            color="warn"
                            *ngIf="
                              (canUpdate || formIsNew) &&
                              (!subject.temporaryFields?.removed ||
                                subject.temporaryFields?.isNew)
                            "
                            (click)="
                              onDeleteAgendaSubject(
                                subject,
                                0,
                                $event,
                                expPanel,
                                ind
                              )
                            "
                          >
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ng-template matExpansionPanelContent>
                      <app-agenda-subject
                        [editedItem]="subject"
                        (itemChanged)="
                          onChildItemChange($event, 0, ind, expPanel)
                        "
                      >
                      </app-agenda-subject>
                    </ng-template>
                  </mat-expansion-panel>
                </ng-container>
              </mat-accordion>
            </div>

            <div class="flex-rows">
              <button
                mat-flat-button
                color="accent"
                class="no-grow"
                [disabled]="!canUpdate"
                (click)="onChildItemAdd($event, 0)"
              >
                <mat-icon>forms_add_on</mat-icon>
                Agendapunt toevoegen
              </button>
            </div>
          </div>

          <h4 class="mat-subheading-1 subjects-title">
            Jaarlijkse onderwerpen
          </h4>

          <div
            class="yearly-subjects children-expansion-container"
            cdkDropList
            #yearlySubjects="cdkDropList"
            [cdkDropListData]="yearlyPlannedSubjectList"
            [cdkDropListConnectedTo]="[subjectsListDrop]"
            [cdkDropListEnterPredicate]="noReturnPredicate"
            (cdkDropListDropped)="drop($event)"
          >
            <mat-card
              class="mat-elevation-z0 insertable-item"
              cdkDrag
              [cdkDragData]="subject"
              [cdkDragDisabled]="!canUpdate"
              *ngFor="let subject of yearlyPlannedSubjectList"
            >
              <span>{{ subject.name ? subject.name : '' }}</span>
            </mat-card>
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel
        class="notes-panel mat-elevation-z0"
        #notesPanel
        [disabled]="!allowedToSeeNotes"
        [expanded]="fullScreenMode"
      >
        <mat-expansion-panel-header>
          <mat-panel-title
            ><span i18n="add-edit-agenda-meeting-dialog expansion-panel-title">
              Notulen &amp; Actiepunten</span
            >
            <button
              mat-flat-button
              color="accent"
              class="create-notes"
              (click)="onCreateNotes($event, notesPanel)"
              [disabled]="!canUpdate"
              i18n="cancel button @@buttonCancel"
              *ngIf="allowedToSeeNotes && !newFormGroup.get('note')?.value"
            >
              <mat-icon>forms_add_on</mat-icon>
              Agendapunten toevoegen
            </button>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div
            class="notes-actionpoints"
            [ngClass]="{ 'full-screen': fullScreenMode }"
          >
            <div class="full-screen-icon">
              <button
                mat-flat-button
                color="primary"
                (click)="toggleFullScreen()"
              >
                Volledig scherm
                <mat-icon
                  aria-label="fullscreen"
                  *ngIf="!fullScreenMode"
                  >fullscreen</mat-icon
                >
                <mat-icon
                  aria-label="fullscreen"
                  *ngIf="fullScreenMode"
                  >fullscreen_exit</mat-icon
                >
              </button>
            </div>
            <div class="meeting-notes">
              <form [formGroup]="newFormGroup">
                <div
                  *ngIf="allowedToSeeNotes && canUpdate"
                  formControlName="note"
                  [ngxSummernote]="summerNoteConfig"
                ></div>
                <div
                  *ngIf="allowedToSeeNotes && !canUpdate"
                  [ngxSummernoteView]="newFormGroup.get('note')?.value"
                ></div>
              </form>
            </div>

            <h4 class="mat-subheading-1">Actiepunten</h4>

            <div class="subjects">
              <div class="children-expansion-container">
                <mat-accordion
                  displayMode="flat"
                  multi
                >
                  <ng-container *ngIf="agendaPointsList.length">
                    <mat-expansion-panel
                      class="mat-elevation-z0 insertable-item"
                      #expPanel2
                      *ngFor="let task of agendaPointsList; let inde = index"
                      [ngClass]="{
                        'is-new': task.temporaryFields?.isNew,
                        'removed': task.temporaryFields?.removed,
                        'has-error': task.temporaryFields?.invalid
                      }"
                      [disabled]="task.temporaryFields?.removed || !canUpdate"
                      [expanded]="task.temporaryFields?.expanded && canUpdate"
                      (opened)="task.expanded = true"
                      (closed)="task.expanded = false"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon *ngIf="task.temporaryFields?.invalid"
                            >error</mat-icon
                          >

                          <span>{{
                            task.title
                              ? task.title
                              : 'Nieuwe actiepunt
                                ' +
                                (inde + 1)
                          }}</span>

                          <div
                            class="mat-expansion-panel-header-label"
                            [ngClass]="{
                              completed: task.statusOfLastTask === 'COMPLETED'
                            }"
                            *ngIf="!task.temporaryFields?.isNew"
                          >
                            {{
                              task.statusOfLastTask === 'COMPLETED'
                                ? 'GEREED'
                                : 'TO DO'
                            }}
                          </div>

                          <div class="cud-icons">
                            <button
                              mat-icon-button
                              color="accent"
                              *ngIf="
                                (canUpdate || formIsNew) &&
                                task.temporaryFields?.removed
                              "
                              (click)="
                                onChildItemRestore(task, 1, $event, inde)
                              "
                            >
                              <mat-icon>restore_from_trash</mat-icon>
                            </button>

                            <button
                              mat-icon-button
                              color="warn"
                              *ngIf="
                                (canUpdate || formIsNew) &&
                                (!task.temporaryFields?.removed ||
                                  task.temporaryFields?.isNew)
                              "
                              (click)="
                                onChildItemDelete(
                                  task,
                                  1,
                                  $event,
                                  expPanel2,
                                  inde
                                )
                              "
                            >
                              <mat-icon>cancel</mat-icon>
                            </button>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <ng-template matExpansionPanelContent>
                        <app-add-edit-tasks-template-embedded
                          [editedItem]="task"
                          (itemChanged)="
                            onChildItemChange($event, 1, inde, expPanel2)
                          "
                        >
                        </app-add-edit-tasks-template-embedded>
                      </ng-template>
                    </mat-expansion-panel>
                  </ng-container>
                </mat-accordion>
              </div>

              <div class="flex-rows">
                <button
                  mat-flat-button
                  color="accent"
                  class="no-grow"
                  [disabled]="!canUpdate"
                  (click)="onChildItemAdd($event, 1)"
                >
                  <mat-icon>format_list_bulleted_add</mat-icon>
                  Actiepunt toevoegen
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>

  <mat-dialog-actions
    [matTooltip]="tooltipMessage"
    matTooltipClass="errors-list-tooltip"
    matTooltipPosition="above"
    [matTooltipDisabled]="!newFormGroup?.invalid"
  >
    <button
      mat-flat-button
      color="primary"
      (click)="onCancel(true)"
      i18n="cancel button @@buttonCancel"
    >
      Annuleren
    </button>
    <button
      mat-flat-button
      color="accent"
      *ngIf="!formSubmitted"
      (click)="onSubmit()"
      [disabled]="
        !(formChanged || childChanged) ||
        newFormGroup?.invalid ||
        childInvalid ||
        !canUpdate
      "
      i18n="save button @@buttonSave"
    >
      Opslaan
    </button>
    <button
      mat-flat-button
      color="accent"
      *ngIf="formSubmitted"
      class="submitted"
      i18n="saved button @@buttonSaved"
    >
      <mat-icon>check</mat-icon> Opgeslagen
    </button>
    <button
      mat-icon-button
      class="printButton"
      printTitle="{{ initialFormValues.title[0] }}"
      [useExistingCss]="true"
      printSectionId="print-section"
      ngxPrint
    >
      <mat-icon>print</mat-icon>
    </button>
    <button
      mat-icon-button
      mat-dialog-close
      class="dialog-action-close"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </mat-dialog-actions>
</ng-container>

<!-- Template used only for printing puropses, its intentionaly hidden
     Since its used only in this component, there is no need to create a separate file for print handling template
     In case this functionality will be extended to other components, it is recomended to move this template to separate file -->
<ng-container hidden>
  <div class="printed">
    <div
      class="dialog-content"
      *ngIf="newFormGroup"
    >
      <mat-card class="mat-elevation-z0">
        <div id="print-section">
          <div
            class="tego"
            id="print-section"
          >
            <mat-card-header class="title">
              <h2>{{ initialFormValues.title[0] }}</h2>
            </mat-card-header>

            <hr />

            <mat-card-header>
              <h2>
                <mat-icon>calendar_month</mat-icon
                >{{
                  initialFormValues.beginDate[0] | date: 'fullDate' | titlecase
                }}
              </h2>
            </mat-card-header>
            <mat-card-content>
              <span>
                <strong>Begintijd:</strong>
                {{ initialFormValues.beginHour[0] }}</span
              >
              <span>
                <strong>Eindtijd:</strong>
                {{ initialFormValues.endHour[0] }}</span
              >
            </mat-card-content>

            <hr />

            <mat-card-header>
              <h3>
                <mat-icon>supervisor_account</mat-icon>
                Delnemers:
              </h3>
            </mat-card-header>
            <mat-card-content>
              <ul *ngFor="let member of initialFormValues.meetingMembers[0]">
                <li *ngIf="member.invited">
                  {{ member.memberName | titlecase }}
                </li>
              </ul>
            </mat-card-content>

            <hr />

            <mat-card-header *ngIf="subjectsList">
              <h3><mat-icon>assignment</mat-icon> Agendapunten:</h3>
            </mat-card-header>
            <mat-card-content>
              <ul *ngFor="let subject of subjectsList">
                <li>{{ subject.name }}</li>
                <li *ngIf="subject.description">{{ subject.description }}</li>
              </ul>
            </mat-card-content>

            <hr *ngIf="initialFormValues.note[0]" />

            <div *ngIf="initialFormValues.note[0]">
              <mat-card-header>
                <h3>
                  <mat-icon>notes</mat-icon>
                  Notulen &amp; Actiepunten
                </h3>
              </mat-card-header>
              <mat-card-content>
                <div
                  class="notes"
                  [innerHTML]="initialFormValues.note[0]"
                ></div>
              </mat-card-content>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</ng-container>
