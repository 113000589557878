/** @format */

import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Theme } from '../models/theme';
import { SubTheme } from '../models/subtheme';
import { Score } from '../models/score';
import { environment } from '../../environments/environment';
import { PageSetting } from '@iq/ng-core';

const apiPath = environment.apiPath;

/**
 * Service that handles the lamp logic
 *
 * @export
 * @class LampService
 */
@Injectable()
export class LampService {
  /**
   * Creates an instance of LampService.
   *
   * @param         {HttpClient}      http
   * @memberof      LampService
   */
  constructor(private http: HttpClient) {}

  /**
   *  Fetch the themes assigned to the member
   *
   * @returns       {Observable<Array<Theme>>}    Observable list of themes in the lamp
   * @memberof      LampService
   */
  getThemes(): Observable<Array<Theme>> {
    return this.http
      .get<any>(apiPath + 'themes')
      .pipe(map((themes) => themes._embedded.themes));
  }

  /**
   *  Fetch the subthemes by the themeId
   *
   * @param                 {string}          [themeId]
   * @param                 {string}          [teamId]
   * @param                 {PageSetting}     [pageSettings]
   * @returns               {Observable<Array<SubTheme>>}    Observable list of subthemes in the theme
   * @memberof              LampService
   */
  getSubThemes(
    themeId?: string,
    teamId?: string,
    pageSettings?: PageSetting,
  ): Observable<Array<SubTheme>> {
    let requestUrl = apiPath + 'sub-themes';

    if (themeId || teamId || pageSettings) {
      requestUrl += '?';
    }

    if (themeId) {
      if (!requestUrl.endsWith('?')) {
        requestUrl += '&';
      }
      requestUrl += 'themeId=' + themeId;
    }

    if (teamId) {
      if (!requestUrl.endsWith('?')) {
        requestUrl += '&';
      }
      requestUrl += 'teamId=' + teamId;
    }

    // Append paging
    if (pageSettings) {
      if (!requestUrl.endsWith('?')) {
        requestUrl += '&';
      }
      requestUrl += `page=${pageSettings.number}&size=${pageSettings.size}`;
    }

    return this.http
      .get<any>(requestUrl)
      .pipe(map((subthemes) => subthemes._embedded.subThemes));
  }

  /**
   *  Create the Object from subThemes { subThemeid: {subTheme} }
   *
   * @param                 {string}          [themeId]
   * @param                 {string}          [teamId]
   * @param                 {PageSetting}     [pageSettings]
   * @returns               {Observable<any>}
   * @memberof              LampService
   */
  getSubThemesMatrix(
    themeId?: string,
    teamId?: string,
    pageSettings?: PageSetting,
  ): Observable<any> {
    return this.getSubThemes(themeId, teamId, pageSettings).pipe(
      map((subthemes) => {
        const subThemesMatrix = {};

        for (const subtheme of subthemes) {
          subThemesMatrix[subtheme.id] = subtheme;
        }

        return subThemesMatrix;
      }),
    );
  }

  /**
   *  Post the new score for the scoreItem.
   *
   * @param         {Score}               score     The new score to save.
   * @returns       {Observable<Score>}
   * @memberof      LampService
   */
  addNewScore(score: Score): Observable<Score> {
    return this.http.post<any>(apiPath + 'scores', score);
  }
}
