/** @format */

import { Component, OnInit } from '@angular/core';
import {
  MarviqMatTableInlineCrudComponent,
  PagedResult,
  PageSetting,
} from '@iq/ng-core';

import { UsersListService } from '../../services/users-list.service';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TeamsListService } from '../../services/teams-list.service';
import { Team } from '../../models/team';
import { map, startWith } from 'rxjs/operators';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { UserRights } from '../../models/user-rights';
import { MembersService } from '../../services/members.service';
import { Member } from '../../models/member';
import { AddEditUserComponent } from '../../dialogs/add-edit-user/add-edit-user.component';
import { Role } from '../../models/role';
import { SharedService } from '../../services/shared.service';
import { CacheUpdateService } from '../../services/cache-update.service';
import { UserValidatorService } from '../../services/user-validator.service';

/**
 *  Display an overview of users.
 *
 * @export
 * @class       UsersListComponent
 * @extends     {MarviqMatTableInlineCrudComponent<User>}
 * @implements  {OnInit}
 */
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent
  extends MarviqMatTableInlineCrudComponent<Member>
  implements OnInit
{
  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                UsersListComponent
   */
  columnsToDisplay = [
    'emailAddress',
    'firstName',
    'lastName',
    'teamRole',
    'CUD',
  ];

  /**
   *  The default values of the new Form.
   *
   * @memberof                UsersListComponent
   */
  emptyFormValues = {
    teamAdmin: [false],
    teamRole: [<Role>{}, Validators.required],
    team: <Team>{},
    emailAddress: ['', [Validators.required, Validators.email]],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    teamId: '',
    user: {
      organisationAdmin: false,
      emailAddress: '',
      firstName: '',
      lastName: '',
    },
  };

  /**
   *  The current user rights.
   *
   * @type                  {UserRights}
   * @memberof              UsersListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The list of teams that user is a member.
   *
   * @type                  {Team[]}
   * @memberof              UsersListComponent
   */
  currentUserTeams: Team[];

  /**
   *  Filtered list of teams
   *
   * @type                  {Observable<Team[]>}
   * @memberof              UsersListComponent
   */
  filteredTeams: Observable<Team[]>;

  /**
   *  Form Control of the filter teams
   *
   * @type                  {FormControl}
   * @memberof              UsersListComponent
   */
  filterControl: UntypedFormControl = new UntypedFormControl();

  /**
   *  The currently selected team from the select list.
   *
   * @type                  {Team}
   * @memberof              UsersListComponent
   */
  selectedTeam: Team;

  /**
   *  The list of the roles in the team.
   *
   * @type                  {Role[]}
   * @memberof              UsersListComponent
   */
  teamRolesList: Role[];

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof              UsersListComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                UsersListComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);
  isUserOfficeManagerOrQualityManager: boolean;

  /**
   * Creates an instance of UsersListComponent.
   *
   * @param     {UsersListService}    usersListService
   * @param     {MembersService} membersService
   * @param     {TeamsListService}    teamsListService
   * @param     {UserValidatorService} userValidatorService
   * @param     {SharedService}       sharedService
   * @param     {FormBuilder}         formBuilder
   * @param     {CacheUpdateService} cacheUpdateService
   * @param dg
   * @param editDialog
   * @memberof  UsersListComponent
   */
  constructor(
    private usersListService: UsersListService,
    private membersService: MembersService,
    private teamsListService: TeamsListService,
    private userValidatorService: UserValidatorService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private cacheUpdateService: CacheUpdateService,
    private dg: MatDialog,
    private editDialog: MatDialog,
  ) {
    super(membersService, formBuilder, dg);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof      UsersListComponent
   */
  ngOnInit() {
    this.currentUserRights = JSON.parse(localStorage.getItem('userRights'));
    this.teamRolesList = this.sharedService.teamRolesList.getValue();

    this.isUserOfficeManagerOrQualityManager =
      this.currentUserRights.currentMember.teamRole.name === 'OFFICE_MANAGER' ||
      this.currentUserRights.currentMember.teamRole.name === 'QUALITY_MANAGER';

    this.dialogParameters.data.message = `Weet je zeker dat je deze gebruiker wilt verwijderen? De gebruiker zal uit het praktijk worden gehaald.`;
    this.queryParams = [
      { name: 'teamId', value: this.currentUserRights.currentTeamId },
    ];

    this.emptyFormValues = {
      teamAdmin: [false],
      teamRole: [this.teamRolesList[0], Validators.required],
      team: this.currentUserRights.currentMember.team,
      teamId: this.currentUserRights.currentTeamId,
      emailAddress: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      user: {
        organisationAdmin: false,
        emailAddress: '',
        firstName: '',
        lastName: '',
      },
    };

    //  Get currentUserTeams
    //
    if (
      this.currentUserRights.organisationAdmin ||
      this.isUserOfficeManagerOrQualityManager
    ) {
      this.teamsListService
        .getAll(undefined, undefined, { name: 'asc' }, undefined)
        .subscribe((response) => {
          this.currentUserTeams = response.payload;

          this.filteredTeams = this.filterControl.valueChanges.pipe(
            startWith(''),
            map((val) => {
              if (val === '') {
                this.onTeamSelect(this.currentUserRights.currentMember.team);

                this.queryParams = [
                  {
                    name: 'teamId',
                    value: this.currentUserRights.currentTeamId,
                  },
                ];

                return this.currentUserTeams;
              } else {
                return this.teamsListService.filterTeam(
                  this.currentUserTeams,
                  val,
                );
              }
            }),
          );
        });
    }

    this.getRows();
  }

  /**
   *  Respond to the resend activation intent
   *
   * @param     {*}         row
   * @memberof  UsersListComponent
   */
  onResendActivation(row: any) {
    this.usersListService.resendActivationEmail(row.user.id).subscribe(() => {
      console.log('Email sent');
    });
  }

  /**
   *  Respond to the selection of the Team in team-select list
   *
   * @param       {Team}              team
   * @memberof    UsersListComponent
   */
  onTeamSelect(team: Team) {
    if (
      !(
        this.currentUserRights.organisationAdmin ||
        this.isUserOfficeManagerOrQualityManager
      )
    ) {
      return;
    }

    this.selectedTeam = team;

    this.emptyFormValues = {
      teamAdmin: [false],
      teamRole: [this.teamRolesList[0], Validators.required],
      team: team,
      emailAddress: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      teamId: team.id,
      user: {
        organisationAdmin: false,
        emailAddress: '',
        firstName: '',
        lastName: '',
      },
    };

    this.queryParams = [{ name: 'teamId', value: team.id }];
    this.getRows();
  }

  /**
   *  Overrides the getRows() method from MarviqMatTable component
   *  Get all rows for the table view
   *
   * @memberof    UsersListComponent
   */
  getRows() {
    this.serviceSubscription = this.membersService
      .getAll(
        this.usersPageSetting,
        this.filterValues,
        { createDate: 'desc' },
        this.queryParams,
      )
      .pipe(map((resp: PagedResult<Member>) => resp))
      .subscribe((result) => {
        const results = result.payload;

        this.dataSource.data = results;

        if (result.page) {
          this.usersPageSetting = result.page;
        }
      });
  }

  /**
   *  Overrides the onEditIntent() method from the parent component
   *  Respond to the user editing a single item.
   *
   * @param         {*}               row
   * @param         {Event}           [event]
   * @returns
   * @memberof      UsersListComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};

    if (row) {
      isNew = false;
    }

    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
    } else {
      const editedRowFormValues = {
        teamAdmin: [row.teamAdmin],
        teamRole: [row.teamRole ? row.teamRole : null, Validators.required],
        id: row.id,
        team: row.team,
        emailAddress: [row.user.emailAddress],
        firstName: [row.user.firstName, Validators.required],
        lastName: [row.user.lastName, Validators.required],
        teamId: row.team.id,
        user: [row.user],
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.editDialog.open(AddEditUserComponent, {
      data: { formValues: formValues, isNew: isNew },
      disableClose: true,
      height: '500px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'users-teams-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getRows();
      }
    });
  }

  /**
   *  Overrides the onDeleteIntent() method from the parent component
   *  Respond to the user deleting a single item.
   *
   * @param         event The (`click`) event signalling the user's intent.
   * @param         row   The current row in the data table
   * @memberof      UsersListComponent
   */
  onDeleteIntent(row: any, event?: Event, dialog?: any) {
    if (event) {
      event.stopImmediatePropagation();
    }

    this.dialogParameters.data.name = row.user.emailAddress;

    //  We have to send the body with the delete request
    //
    const editedRowFormValues = {
      teamId: this.selectedTeam
        ? this.selectedTeam.id
        : this.currentUserRights.currentTeamId,
      id: row.id,
    };

    const dialogRef = this.dg.open(dialog, this.dialogParameters);

    dialogRef.afterClosed().subscribe((results) => {
      if (!results) {
        return;
      }
      //  Delete method with the body param
      this.membersService.deleteMember(editedRowFormValues).subscribe(
        (resp) => {
          row.hasError = false;
          this.updateTable(row, resp, 'delete');
          if (
            this.userValidatorService.currentUserRights.getValue()
              .currentTeamId === editedRowFormValues.teamId
          ) {
            this.cacheUpdateService.updateTeamMembersList();
          }
        },
        (err) => {
          row.hasError = true;
        },
      );
    });
  }
}
