/** @format */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Theme } from '../../models/theme';
import { Subscription } from 'rxjs';
import { LampService } from '../../services/lamp.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

/**
 *  The improvement plan container.
 *
 * @export
 * @class           ImprovementPlanComponent
 * @implements      {OnInit}
 * @implements      {OnDestroy}
 */
@Component({
  selector: 'app-improvement-plan',
  templateUrl: './improvement-plan.component.html',
  styleUrls: ['./improvement-plan.component.scss'],
})
export class ImprovementPlanComponent implements OnInit, OnDestroy {
  /**
   *  The index of selected tab.
   *
   * @memberof        ImprovementPlanComponent
   */
  selectedTabInd = 0;

  /**
   *  Types of swipe actions.
   *
   * @memberof        TheLampComponent
   */
  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  /**
   *  The list of themes returned from the API
   *
   * @type            {Array<Theme>}
   * @memberof        ImprovementPlanComponent
   */
  themesList: Array<Theme>;

  /**
   *  The subscription of the themesList
   *
   * @type            {Subscription}
   * @memberof        ImprovementPlanComponent
   */
  themesListSubs: Subscription;

  /**
   * Creates an instance of ImprovementPlanComponent.
   *
   * @param     {LampService}           lampService     Service that handles lamp logic.
   * @param     {ChangeDetectorRef}     ref
   * @memberof  ImprovementPlanComponent
   */
  constructor(
    private lampService: LampService,
    private ref: ChangeDetectorRef,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof ImprovementPlanComponent
   */
  ngOnInit() {
    this.themesListSubs = this.lampService.getThemes().subscribe((themes) => {
      this.themesList = themes;
    });
  }

  /**
   *  Respond when the selected tab changed
   *
   * @param       {MatTabChangeEvent} [event]
   * @memberof    ImprovementPlanComponent
   */
  tabSelectionChanged(event?: MatTabChangeEvent) {
    this.selectedTabInd = event.index;
  }

  /**
   *  Respond to the swipe event on the content
   *
   * @param         {string}          eventType
   * @memberof      TheLampComponent
   */
  onSwipe(eventType: string) {
    if (eventType === this.SWIPE_ACTION.LEFT && this.selectedTabInd > 0) {
      this.selectedTabInd--;
    } else if (
      eventType === this.SWIPE_ACTION.RIGHT &&
      this.selectedTabInd < this.themesList.length - 1
    ) {
      this.selectedTabInd++;
    }

    this.ref.detectChanges();
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof ImprovementPlanComponent
   */
  ngOnDestroy(): void {
    this.themesListSubs.unsubscribe();
    this.ref.detach();
  }
}
