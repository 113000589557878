/** @format */

import { Injectable } from '@angular/core';
import { UserValidatorService } from './user-validator.service';
import { SharedService } from './shared.service';
import { MembersService } from './members.service';
import { RolesService } from './roles.service';
import { YearlyPlannedSubjectsService } from './yearly-planned-subjects.service';
import { FilesLibraryService } from './files-library.service';
import { LampService } from './lamp.service';

/**
 *  Service for updating local cache (shared service).
 *
 * @export
 * @class   CacheUpdateService
 */
@Injectable()
export class CacheUpdateService {
  constructor(
    private userValidatorService: UserValidatorService,
    private sharedService: SharedService,
    private membersService: MembersService,
    private yearlyPlannedSubjectsService: YearlyPlannedSubjectsService,
    private filesService: FilesLibraryService,
    private lampService: LampService,
    private rolesService: RolesService,
  ) {}

  public updateTeamMembersList(): void {
    this.membersService
      .getAll(undefined, undefined, undefined, [
        { name: 'teamId', value: this.getCurrentTeamId() },
      ])
      .subscribe((members) => {
        this.sharedService.newTeamMembersList(members.payload);
      });
  }

  public updateTeamRolesList(): void {
    this.rolesService
      .getAll(undefined, undefined, undefined, undefined)
      .subscribe((roles) => {
        this.sharedService.newTeamRolesList(roles.payload);
      });
  }

  public updateYearlyPlannedSubjects(): void {
    this.yearlyPlannedSubjectsService
      .getAll(undefined, undefined, undefined, [
        { name: 'teamId', value: this.getCurrentTeamId() },
      ])
      .subscribe((subjects) => {
        this.sharedService.newYearlyPlannedSubjects(subjects.payload);
      });
  }

  public updateFilesCategories(): void {
    this.filesService.getFilesCategories().subscribe((categories) => {
      this.sharedService.newFilesCategories(categories);
    });
  }

  public updateThemesList(): void {
    const themesWithIds = <any>{};

    this.lampService.getThemes().subscribe((themes) => {
      this.sharedService.newThemesList(themes);
      for (const theme of themes) {
        if (theme.id && !themesWithIds[theme.id]) {
          themesWithIds[theme.id] = theme;
        }
      }
      this.sharedService.newThemesWithIdsList(themesWithIds);
    });
  }

  private getCurrentTeamId(): string {
    return this.userValidatorService.currentUserRights.getValue().currentTeamId;
  }
}
