/** @format */

import {
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CHART_TYPES } from '../../models/sunburst';
import { UserValidatorService } from '../../services/user-validator.service';
import { Router } from '@angular/router';
import { SunburstGraphService } from '../../services/sunburst-graph.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

export interface ContactFormData {
  graphType: CHART_TYPES;
  dimensionId: string;
  questionId: string;
  cellShortName: string;
}

interface ContactObjToSend {
  additionalInfo: string;
  benchmarkResultType: BackendChartEnums;
  senderEmailAddress: string;
  sunburstId: string;
}

type BackendChartEnums = 'INNOVATION' | 'QUALITY' | 'COMPLIANCE';

@Component({
  selector: 'app-charts',
  templateUrl: './graph-contact-form.component.html',
  styleUrls: ['./graph-contact-form.component.scss'],
})
export class GraphContactFormComponent implements OnInit {
  #userValidationService = inject(UserValidatorService);
  #router = inject(Router);
  #sunburstGraphService = inject(SunburstGraphService);
  #http = inject(HttpClient);
  #snackBar = inject(MatSnackBar);

  contactCoachForm = new FormGroup({
    heading: new FormControl(),
    additionalInfo: new FormControl()
  })

  ngOnInit(): void {
    const serviceFormData = this.#sunburstGraphService.contactFormData$.getValue();
    if (!serviceFormData) {
      this.#router.navigate(['graphs/list']);
    } else {
      this.contactCoachForm.controls.heading.setValue(`Hallo, ik heb hulp nodig met onderwerp ${serviceFormData.cellShortName.toLowerCase()} in ${serviceFormData.graphType.toLowerCase()} sunburst`)
    }
  }

  submitForm(): void {
    const serviceFormData = this.#sunburstGraphService.contactFormData$.getValue();
    const apiPath = environment.apiPath;

    const objToSend: ContactObjToSend = {
      additionalInfo: this.contactCoachForm.controls.additionalInfo.value,
      benchmarkResultType: this.#mapChartEnumToBackendEnum(serviceFormData.graphType),
      senderEmailAddress: this.#userValidationService.currentUser.getValue().emailAddress,
      sunburstId: this.#constructSunburstId(serviceFormData.dimensionId, serviceFormData.questionId)
    };

    this.#http.post(`${apiPath}/mail/benchmark-results`, objToSend).pipe(catchError(() => {
      this.#snackBar.open('Er is iets fout gegaan', null, { duration: 2500 });
      return of()
    })).subscribe(() => {
      this.#router.navigate(['graphs/list']);
    });
  }

  #mapChartEnumToBackendEnum(chartType: CHART_TYPES): BackendChartEnums | null {
    if (chartType === CHART_TYPES.INNOVATION) {
      return 'INNOVATION';
    } else if (chartType === CHART_TYPES.QUALITY) {
      return 'QUALITY';
    } else if (chartType === CHART_TYPES.COMPLIANCY) {
      return 'COMPLIANCE';
    } else {
      return null;
    }
  }

  #constructSunburstId(dimensionId: string, questionId: string | null): string {
    if (!questionId) {
      return dimensionId;
    } else {
      return `${dimensionId}/${questionId}`;
    }
  }
}
