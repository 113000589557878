/** @format */

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { LampService } from './lamp.service';
import { Theme } from '../models/theme';

/**
 * The lamp service that pre-fetches the data prior to activating the route
 * using the Resolve guard
 *
 * @export
 * @class TheLampResolverService
 */
@Injectable()
export class TheLampResolverService {
  /**
   * Creates an instance of TheLampResolverService.
   *
   * @param         {LampService}             lampService   The service that handles the lamp logic.
   * @param         {Router}                  router        Instance of the Router.
   * @memberof      TheLampResolverService
   */
  constructor(
    private lampService: LampService,
    private router: Router,
  ) {}

  /**
   * Perform route data retrieval before route activation
   *
   * @param         {ActivatedRouteSnapshot}  route         The activated route snapshot instance.
   * @param         {RouterStateSnapshot}     state         Te router state snapshot instance.
   * @returns       {Observable<Theme[]>}
   * @memberof      TheLampResolverService
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Theme[]> {
    return this.lampService.getThemes().pipe(
      map((themes) => {
        if (themes) {
          return themes;
        } else {
          this.router.navigate(['/home']);

          return null;
        }
      }),
    );
  }
}
